import {
  CartForm,
  CollectionGrid,
  DividerPhoto,
  Grid,
  GridSize,
  Hero,
  ProductHero,
  ReviewWidget,
  Video,
  Marquee,
  BlogPostGrid,
  BlogPostHeader,
  BlogCategoryPostGrid,
  BlogAuthorPostGrid,
} from '@data/sanity/schema'
import { Keyed } from '@lib/helpers'
import { SanityBlocks } from './blocks'
import {
  SanityBlogAuthor,
  SanityBlogCategory,
  SanityBlogPostOptions,
  SanityBlogPostWithoutBody,
} from './blog'
import { SanityContentFragment } from './content'
import { SanityFilter, SanitySort } from './filter'
import { SanityImageFragment } from './image'
import { SanityProductFragment } from './product'
import {
  HasCollectionStrings,
  HasReviewsStrings,
  HasPrintConfiguratorStrings,
} from './strings'
import { SanityMuxVideo, SanityVideo, SanityVimeoVideoMedia } from './video'

export enum SanityModuleType {
  GRID = 'grid',
  HERO = 'hero',
  DIVIDER_PHOTO = 'dividerPhoto',
  PRODUCT_HERO = 'productHero',
  COLLECTION_GRID = 'collectionGrid',
  REVIEW_WIDGET = 'reviewWidget',
  CART_FORM = 'cartForm',
  VIDEO = 'video',
  MARQUEE = 'marquee',
  BLOG_POST_GRID = 'blogPostGrid',
  BLOG_POST_HEADER = 'blogPostHeader',
  BLOG_CATEGORY_POST_GRID = 'blogCategoryPostGrid',
  BLOG_AUTHOR_POST_GRID = 'blogAuthorPostGrid',
}

export enum SanityGridBackground {
  GRADIENT = 'gradient',
  GRAY = 'gray',
  DARK_GRAY = 'dark-gray',
  GREEN = 'green',
  DARK_GREEN = 'dark-green',
  RED = 'red',
  DARK_RED = 'dark-red',
}

// Module component types
export type SanityGridColumnSize = Pick<
  GridSize,
  'breakpoint' | 'width' | 'justify' | 'align' | 'start'
>

export type SanityGridColumn = Keyed<{
  sizes: Array<SanityGridColumnSize>
  blocks: Array<SanityBlocks>
  style: string
}>

export type SanityGrid = Pick<
  Grid,
  'size' | 'style' | 'noColumnGaps' | 'noRowGaps' | 'reverseSequence'
> & {
  background?: SanityGridBackground
  columns: Array<SanityGridColumn>
}

export interface SanityHeroPhotos {
  mobilePhoto?: SanityImageFragment
  desktopPhoto?: SanityImageFragment
}

export type SanityHeroVimeoVideo = Pick<SanityVimeoVideoMedia, 'link' | 'name'>

export enum SanityHeroBackgroundType {
  PHOTO = 'photo',
  VIMEO_VIDEO = 'video',
  MUX_VIDEO = 'mux-video',
}

export enum SanityHeroContentPosition {
  CENTER = 'center',
  BOTTOM_LEFT = 'bottom-left',
}

export interface SanityHero {
  bgType: SanityHeroBackgroundType
  contentPosition: SanityHeroContentPosition
  content?: Array<SanityContentFragment>
  photos?: SanityHeroPhotos
  vimeoVideo?: SanityHeroVimeoVideo
  muxVideo?: SanityMuxVideo
}

export interface SanityDividerPhoto {
  photo: SanityImageFragment
}

export type SanityCollectionGrid = Pick<CollectionGrid, 'active'> & {
  paginationLimit: number
  title?: string
  filter?: SanityFilter
  sort?: SanitySort
}

export enum SanityReviewWidgetType {
  MAIN = 'main',
  PRODUCT = 'product',
}

export type SanityReviewWidget = {
  type: SanityReviewWidgetType
}

export type SanityCartForm = Pick<CartForm, 'active'>

export type SanityMarqueeSimpleItem = {
  _type: 'simple'
  text: string
}

export type SanityMarqueePhotoItem = {
  _type: 'photo'
  photo: SanityImageFragment
}

export type SanityMarqueeProductItem = {
  _type: 'product'
  _id: string
  product: SanityProductFragment
}

export type SanityMarqueeItem = Keyed<
  SanityMarqueeSimpleItem | SanityMarqueePhotoItem | SanityMarqueeProductItem
>

export type SanityMarquee = Pick<Marquee, 'speed' | 'reverse' | 'pausable'> & {
  items: Array<SanityMarqueeItem>
}

export interface SanityBlogPostGrid {
  posts: Array<SanityBlogPostWithoutBody>
  author?: SanityBlogAuthor
  category?: SanityBlogCategory
  options?: SanityBlogPostOptions
}

export interface SanityBlogPostHeader {
  post?: SanityBlogPostWithoutBody
  options?: SanityBlogPostOptions
}

export type SanityBlogCategoryPostGrid = Pick<SanityBlogPostGrid, 'options'>

export type SanityBlogAuthorPostGrid = Pick<SanityBlogPostGrid, 'options'>

// Module raw data types
export type SanityRawModule =
  | BlogPostGrid
  | BlogPostHeader
  | CartForm
  | CollectionGrid
  | DividerPhoto
  | Grid
  | Hero
  | Marquee
  | ProductHero
  | ReviewWidget
  | Video

// Module data types
export type SanityGridModule = Keyed<Pick<Grid, '_type'> & SanityGrid>

export type SanityHeroModule = Keyed<Pick<Hero, '_type'> & SanityHero>

export type SanityDividerPhotoModule = Keyed<
  Pick<DividerPhoto, '_type'> & SanityDividerPhoto
>

export type SanityProductHeroModule = Keyed<
  Pick<ProductHero, '_type'> & HasPrintConfiguratorStrings
>

export type SanityCollectionGridModule = Keyed<
  Pick<CollectionGrid, '_type'> & SanityCollectionGrid & HasCollectionStrings
>

export type SanityReviewWidgetModule = Keyed<
  Pick<ReviewWidget, '_type'> & SanityReviewWidget & HasReviewsStrings
>

export type SanityCartFormModule = Keyed<
  Pick<CartForm, '_type'> & SanityCartForm
>

export type SanityVideoModule = Keyed<Pick<Video, '_type'> & SanityVideo>

export type SanityMarqueeModule = Keyed<Pick<Marquee, '_type'> & SanityMarquee>

export type SanityBlogPostGridModule = Keyed<
  Pick<BlogPostGrid, '_type'> & SanityBlogPostGrid
>

export type SanityBlogPostHeaderModule = Keyed<
  Pick<BlogPostHeader, '_type'> & SanityBlogPostHeader
>

export type SanityBlogCategoryPostGridModule = Keyed<
  Pick<BlogCategoryPostGrid, '_type'> & SanityBlogCategoryPostGrid
>

export type SanityBlogAuthorPostGridModule = Keyed<
  Pick<BlogAuthorPostGrid, '_type'> & SanityBlogAuthorPostGrid
>

export type SanityModule =
  | SanityGridModule
  | SanityHeroModule
  | SanityDividerPhotoModule
  | SanityProductHeroModule
  | SanityCollectionGridModule
  | SanityReviewWidgetModule
  | SanityCartFormModule
  | SanityVideoModule
  | SanityMarqueeModule
  | SanityBlogPostGridModule
  | SanityBlogPostHeaderModule
  | SanityBlogCategoryPostGridModule
  | SanityBlogAuthorPostGridModule
