import { SanityModule } from '@data/sanity/queries/types/modules'
import { SanityProductFragment } from '@data/sanity/queries/types/product'
import { SanityCart } from '@data/sanity/queries/types/site'
import { SanityPrintShop } from '@data/sanity/queries/types/shop'
import { SanityBlogPostWithoutBody } from '@data/sanity/queries/types/blog'
import { Reviews } from '@lib/review'

import Module from './module'

interface ModulesProps {
  modules: SanityModule[] | null
  product?: SanityProductFragment
  posts?: SanityBlogPostWithoutBody[]
  collectionProducts?: SanityProductFragment[]
  featuredProductIds?: number[]
  cartSettings?: SanityCart
  reviews?: Reviews
  printShop?: SanityPrintShop
}

const Modules = ({
  modules,
  reviews,
  product,
  posts,
  collectionProducts,
  featuredProductIds,
  cartSettings,
  printShop,
}: ModulesProps) => {
  if (!modules) {
    return null
  }

  return (
    <>
      {modules.map((module) => (
        <Module
          key={module._key}
          module={module}
          cartSettings={cartSettings}
          posts={posts}
          collectionProducts={collectionProducts}
          featuredProductIds={featuredProductIds}
          product={product}
          reviews={reviews}
          printShop={printShop}
        />
      ))}
    </>
  )
}

export default Modules
